import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import Input from '../../elements/Input';

// Validation schema
const validationSchema = Yup.object({
  fullName: Yup.string().required('Full Name is required'),
  company: Yup.string().required('Company/Organization is required'),
  phone: Yup.string()
    .matches(/^[0-9]+$/, 'Phone number must be digits only')
    .min(10, 'Phone number must be at least 10 digits')
    .required('Phone number is required'),
    email: Yup.string()
    .email("Invalid email format")
    .required("Company email is required"),
  subject: Yup.string().required('Subject is required'),
  message: Yup.string().required('Message is required'),
});

const ContactForm = () => {
  const [isHovered, setIsHovered] = React.useState(false);
  const [isMobile, setIsMobile] = React.useState(false);

  React.useEffect(() => {
    const checkScreenSize = () => {
      setIsMobile(window.innerWidth < 768); // 768px is the breakpoint for mobile
    };

    // Initial check
    checkScreenSize();

    // Add event listener to window resize
    window.addEventListener('resize', checkScreenSize);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('resize', checkScreenSize);
    };
  }, []);

  return (
    <div style={{margin:'5px',padding:'20px',backgroundColor:'#f1f4fd',borderRadius:'10px'}}>
      <Formik
        initialValues={{
          fullName: '',
          company: '',
          phone: '',
          email:'',
          subject: '',
          message: '',
        }}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting }) => {
          setTimeout(() => {
            alert(JSON.stringify(values, null, 2));
            setSubmitting(false);
          }, 400);
        }}
      >
        {({ isSubmitting }) => (
          <Form>
            <div style={{margin:'5px 25px'}}>
              <div >
            <div>
              <label htmlFor="fullName" style={{fontSize:'16px'}} >Full Name*</label><br/>
             <Field type="text"  name="fullName"  style={{width:'100%',height:'40px',border:'1px solid #ccc9c8'}} />
              <ErrorMessage name="fullName" component="div" style={{color:'#e33636',fontSize:'16px'}} />
            </div>

            <div style={{marginTop:'20px'}}>
              <label htmlFor="company" style={{fontSize:'16px'}} >Company / Organization*</label><br/>
              <Field type="text" name="company"   style={{width:'100%',height:'40px',border:'1px solid #ccc9c8'}}/>
              <ErrorMessage name="company" component="div" style={{color:'#e33636',fontSize:'16px'}}/>
            </div>
            </div>
            <div style={{marginTop:'20px',display:'flex',flexDirection: isMobile ? 'column' : 'row',}} >
            <div>
              <label htmlFor="phone" style={{fontSize:'16px'}} >Phone*</label><br/>
              <Field type="text" name="phone" style={{maxWidth: isMobile?'100%': '200px',marginRight:'15px',height:'40px',border:'1px solid #ccc9c8'}}   />
              <ErrorMessage name="phone" component="div"style={{color:'#e33636',fontSize:'16px'}} />
            </div>

            <div>
              <label htmlFor="email" style={{fontSize:'16px'}}>Company email*</label><br/>
              <Field type="text" name="email"  style={{maxWidth: isMobile?'100%': '200px',height:'40px',border:'1px solid #ccc9c8'}}   />
              <ErrorMessage name="email" component="div" style={{color:'#e33636',fontSize:'16px'}}/>
            </div>
</div>
            <div style={{marginTop:'20px'}}>
              <label htmlFor="subject"  style={{fontSize:'16px'}}>Subject</label><br/>
              <Field type="text" name="subject" style={{width:'100%',height:'40px',border:'1px solid #ccc9c8'}} />
              <ErrorMessage name="subject" component="div" style={{color:'#e33636',fontSize:'16px'}}/>
            </div>

            <div style={{marginTop:'20px'}}>
              <label htmlFor="message" style={{fontSize:'16px'}}>Message*</label><br/>
              <Field as="textarea" name="message" rows='4' style={{width:'100%',border:'1px solid #ccc9c8'}}  />
              <ErrorMessage name="message" component="div" style={{color:'#e33636',fontSize:'16px'}} />
            </div>
<div style={{textAlign:'center',marginTop:'30px'}}>
            <button type="submit"
             disabled={isSubmitting}
             onMouseEnter={() => setIsHovered(true)}  
             onMouseLeave={() => setIsHovered(false)}
             style={{backgroundColor:isHovered? "#d6480d":'#f45513',padding:'7px 20px',border:'none',color:'white',borderRadius:'5px',cursor:'pointer'}}>
              Submit
            </button>
            </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default ContactForm;
